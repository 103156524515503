var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-content"},[_c('div',{staticClass:"menu"},[_c('b-form-group',[_c('label',{attrs:{"for":"filiale-input"}},[_vm._v("Filiale")]),_c('b-form-select',{attrs:{"id":"filiale-input","options":_vm.computedAvailableFiliales,"value-field":"id","text-field":"name","disabled":_vm.$route.name === 'Objectifs' ||
            !_vm.computedAvailableFiliales ||
            _vm.$route.name === 'Statistics',"required":""},on:{"change":_vm.hundleFilialeChange},model:{value:(_vm.selectedFiliale),callback:function ($$v) {_vm.selectedFiliale=$$v},expression:"selectedFiliale"}},[(!_vm.computedAvailableFiliales.length)?[_c('b-form-select-option',{attrs:{"value":null,"disabled":""}},[_vm._v(" -- Aucune filiale disponible -- ")])]:_vm._e()],2)],1),_c('b-form-group',[_c('label',{attrs:{"for":"deposit-input"}},[_vm._v("Dépôt")]),_c('b-form-select',{attrs:{"id":"deposit-input","disabled":!_vm.computedAvailableDeposits ||
            !_vm.computedAvailableDeposits.length ||
            _vm.$route.name === 'Objectifs' ||
            !_vm.computedAvailableDeposits ||
            _vm.$route.name === 'Statistics',"options":_vm.computedAvailableDeposits,"value-field":"id","text-field":"name","required":""},on:{"change":_vm.hundleChangeFilter},model:{value:(_vm.selectedDeposit),callback:function ($$v) {_vm.selectedDeposit=$$v},expression:"selectedDeposit"}})],1),_c('b-form-group',[_c('label',{attrs:{"for":"week-input"}},[_vm._v("Semaine")]),_c('b-form-select',{attrs:{"id":"week-input","options":_vm.getAvailableWeeks,"value-field":"id","text-field":"name","required":"","disabled":(_vm.$route.name === 'Objectifs' && !_vm.getReportingObjectifs.loaded) ||
            _vm.$route.name === 'Statistics'},on:{"change":_vm.hundleChangeFilter},model:{value:(_vm.selectedWeek),callback:function ($$v) {_vm.selectedWeek=$$v},expression:"selectedWeek"}}),(_vm.computedSelectedWeek)?_c('div',{staticClass:"dates"},[_vm._v(" "+_vm._s(_vm.computedSelectedWeek.start)+" - "+_vm._s(_vm.computedSelectedWeek.end)+" ")]):_vm._e()],1),_c('div',{staticClass:"menu-links"},[_c('div',{staticClass:"menu-title"},[_vm._v("Stat. opérationnelles")]),_c('div',{staticClass:"menu-link"},[_c('router-link',{staticClass:"menu-item",attrs:{"to":"/reporting/statistics"}},[_vm._v(" Stat. planning ")])],1),_c('div',{staticClass:"menu-link"},[_c('router-link',{staticClass:"menu-item",attrs:{"to":"/reporting/terrain"}},[_vm._v(" Stat. terrain ")])],1),_c('div',{staticClass:"menu-title"},[_vm._v("Perf. globales")]),_c('div',{staticClass:"menu-link"},[_c('router-link',{staticClass:"menu-item",attrs:{"to":"/reporting/objectifs"}},[_vm._v(" Objectifs filiales ")])],1)])],1),_c('div',{staticClass:"content"},[_c('router-view',{attrs:{"selectedWeek":_vm.selectedWeek}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }